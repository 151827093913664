<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialogEntryTest"
        max-width="1200px"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form_entry_test === 'add'"
                >Add entry test</span
              >
              <span class="headline" v-else>Edit entry test</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogEntryTest = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="7" class="pt-0">
                  <v-text-field
                    label="Title"
                    v-model="title_input"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <label style="font-size: 16px">Mục đích</label>
<!--                  <v-textarea dense outlined v-model="purpose_input"></v-textarea>-->
                  <div>
                    <v-card>
                      <v-tabs
                          v-model="purpose_tab"
                          background-color="blue-grey lighten-1"
                          dark
                      >
                        <v-tab  v-for="(item, key) in purpose_input" :key="key">
                          {{ getLabelByLangCode(item.lang) }}
                        </v-tab>
                      </v-tabs>
                    </v-card>

                    <template v-for="(item, key) in purpose_input">
                      <div :key="key">
                        <template v-if="purpose_tab === key">
                          <v-textarea dense filled
                                      v-model="item.text"></v-textarea>
                        </template>
                      </div>
                    </template>
                  </div>

                </v-col>
                <v-col cols="12" class="">
                  <label style="font-size: 16px">Nôi dung</label>
<!--                  <Editor v-model="content_input"></Editor>-->
                  <div>
                    <v-card>
                      <v-tabs
                          v-model="content_tab"
                          background-color="blue-grey lighten-1"
                          dark
                      >
                        <v-tab  v-for="(item, key) in content_input" :key="key">
                          {{ getLabelByLangCode(item.lang) }}
                        </v-tab>
                      </v-tabs>
                    </v-card>

                    <template v-for="(item, key) in content_input">
                      <div :key="key">
                        <template v-if="content_tab === key">
                          <Editor v-model="item.text"></Editor>
                        </template>
                      </div>
                    </template>
                  </div>
                </v-col>
                <v-col cols="12" class="mt-2 pb-0">
                  <label style="font-size: 16px">Chọn thang nhận xét</label>
                  <div class="d-flex">
                    <v-autocomplete
                      v-model="test_result_analysis_id"
                      :items="test_result_analysis"
                      item-text="title"
                      item-value="id"
                      filled
                      chips
                    ></v-autocomplete>
                    <v-btn
                      color="teal"
                      class="ma-2 mt-2 font-weight-bold"
                      outlined
                      @click="openDialogResultAnalysis"
                    >
                      <v-icon left>mdi-plus</v-icon> Tạo thang điểm
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="12" class="pb-0 d-flex">
                  <label style="font-size: 16px">Sử dụng grading scale:</label>
                  <v-switch
                    class="mt-0 pt-0 ml-2"
                    v-model="use_grading_scale"
                    :label="use_grading_scale ? 'Yes' : 'No'"
                  ></v-switch>
                </v-col>
                <v-col cols="12">
                  <label style="font-size: 16px">
                    Chọn bài test:
                    <template
                      v-if="!(title_test === '' || title_input == null)"
                    >
                      <span class="blue--text">( {{ title_test }} )</span>
                    </template>
                  </label>
                  <div>
                    <v-btn
                      color="teal"
                      class="ma-2 mt-2 font-weight-bold"
                      outlined
                      @click="openDialogTestAddFast"
                    >
                      <v-icon left>mdi-plus</v-icon> Tạo bài test
                    </v-btn>
                    <div style="float: right; width: 250px">
                      <b-form-input
                        class="mt-0"
                        style="width: 100%"
                        placeholder="Search"
                        v-model="search_test_input"
                        @keyup.enter="enterSearchTest()"
                      ></b-form-input>
                      <span
                        style="clear: both; display: inline-block"
                        class="text-subtitle-1 grey--text darken-1"
                        >(Enter để tìm kiếm)</span
                      >
                    </div>
                    <div class="table-responsive" style="overflow: hidden">
                      <table
                        class="table table-bordered table-head-custom table-vertical-center table-head-bg"
                      >
                        <thead class="thead-ipp">
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Title</th>
                            <th scope="col">Limit time</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="(test, i) in tests">
                            <tr :key="i">
                              <td>
                                <v-radio-group
                                  v-model="test_id"
                                  class="mt-0 mb-0 pt-0"
                                  style="max-height: 25px"
                                >
                                  <v-radio
                                    label=""
                                    :value="test.id"
                                    @click="chooseTest(i)"
                                  ></v-radio>
                                </v-radio-group>
                              </td>
                              <td>
                                <span
                                  class="text-dark-75 font-weight-normal d-block font-size-lg"
                                  >#{{ test.id }} - {{ test.title }}</span
                                >
                              </td>
                              <td>
                                <span
                                  class="label label-lg label-inline font-weight-bold py-4"
                                  v-bind:class="
                                    test.is_limit_time === 1
                                      ? 'label-light-success'
                                      : 'label-light-warning'
                                  "
                                >
                                  {{ test.is_limit_time === 1 ? "Yes" : "No" }}
                                </span>
                              </td>
                            </tr>
                          </template>
                          <template v-if="tests.length <= 0">
                            <tr>
                              <td style="text-align: center" colspan="3">
                                No data
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                    <v-pagination
                      style="float: right"
                      color="#00BCD4"
                      v-model="data_pagination_test.current_page"
                      :length="data_pagination_test.total_pages"
                      :total-visible="6"
                    ></v-pagination>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form_entry_test === 'add'"
              @click="btnAddOrEditEntryTest(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form_entry_test === 'update'"
              @click="btnAddOrEditEntryTest(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogEntryTest = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
  name: "EntryTestAdd",
  components: {
    Editor: () => import("@/components/ckeditor5/Editor.vue"),
  },
  props: {
    entry_test: {},
    entry_test_id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      title_input: "",
      purpose_tab: 0,
      purpose_input: [
        {lang: 'vi', text: ''},
        {lang: 'en', text: ''},
        {lang: 'th', text: ''},
      ],
      content_tab: 0,
      content_input: [
        {lang: 'vi', text: ''},
        {lang: 'en', text: ''},
        {lang: 'th', text: ''},
      ],
      use_grading_scale: false,
      tests: [],
      data_pagination_test: {
        current_page: 1,
        total_pages: 1,
      },
      timer_query: null,
    };
  },
  computed: {
    ...mapGetters("entryTestStore", ["test_result_analysis"]),
    is_call_api: {
      get() {
        return this.$store.state.entryTestStore.is_call_api;
      },
      set(value) {
        this.$store.commit("entryTestStore/setIsCallApi", value);
      },
    },
    dialogEntryTest: {
      get() {
        return this.$store.state.entryTestStore.dialogEntryTest;
      },
      set(value) {
        this.$store.commit("entryTestStore/setDialogEntryTest", value);
      },
    },
    type_form_entry_test: {
      get() {
        return this.$store.state.entryTestStore.type_form_entry_test;
      },
      set(value) {
        this.$store.commit("entryTestStore/setTypeFormEntryTest", value);
      },
    },
    dialogResultAnalysisAddFast: {
      get() {
        return this.$store.state.entryTestStore.dialogResultAnalysisAddFast;
      },
      set(value) {
        this.$store.commit(
          "entryTestStore/setDialogResultAnalysisAddFast",
          value
        );
      },
    },
    test_result_analysis_id: {
      get() {
        return this.$store.state.entryTestStore.test_result_analysis_id;
      },
      set(value) {
        this.$store.commit("entryTestStore/setTestResultAnalysisId", value);
      },
    },
    test_id: {
      get() {
        return this.$store.state.entryTestStore.test_id;
      },
      set(value) {
        this.$store.commit("entryTestStore/setTestId", value);
      },
    },
    title_test: {
      get() {
        return this.$store.state.entryTestStore.title_test;
      },
      set(value) {
        this.$store.commit("entryTestStore/setTitleTest", value);
      },
    },
    dialogAddTestFast: {
      get() {
        return this.$store.state.entryTestStore.dialogAddTestFast;
      },
      set(value) {
        this.$store.commit("entryTestStore/setDialogAddTestFast", value);
      },
    },
    search_test_input: {
      get() {
        return this.$store.state.entryTestStore.search_test_input;
      },
      set(value) {
        this.$store.commit("entryTestStore/setSearchTestInput", value);
      },
    },
    test_id_selected: {
      get() {
        return this.$store.state.entryTestStore.test_id_selected;
      },
      set(value) {
        this.$store.commit("entryTestStore/setTestIdSelected", value);
      },
    },
  },
  watch: {
    "data_pagination_test.current_page": function (page) {
      this.test_id = null;
      this.actionSearchTest(page, this.search_test_input);
    },
    // search_test_input: function(val) {
    //   this.actionSearchTest(1, val);
    // },
    dialogEntryTest(val) {
      if (val) {
        this.setDataForm();
      }
    },
  },
  created() {
    this.getAllResultAnalysis();
    this.getAllTest();
  },
  methods: {
    setDataForm() {
      this.purpose_tab = 0
      this.content_tab = 0
      if (this.type_form_entry_test === "update") {
        let data = this.entry_test;
        this.title_input = data.title;
        this.purpose_input = (data.purpose.length > 0 ) ? data.purpose : this.defaultLangContent();
        this.content_input = (data.content.length > 0 ) ? data.content : this.defaultLangContent();
        this.test_result_analysis_id = data.resultAnalysis.id;
        this.title_test = data.test.title;
        this.test_id = data.test.id;
        this.test_id_selected = data.test.id;
        this.search_test_input = data.test.title;
        this.use_grading_scale = data.use_grading_scale === 1;
      } else {
        this.title_input = "";
        this.purpose_input = this.defaultLangContent();
        this.content_input = this.defaultLangContent();
        this.test_id = null;
        this.test_id_selected = null;
        this.test_result_analysis_id = null;
        this.title_test = "";
        this.search_test_input = "";
        this.use_grading_scale = false;
      }
    },
    async getAllResultAnalysis() {
      let vm = this;
      await ApiService.get("prep-app/test-result-analysis/limit").then(
        function (response) {
          if (response.status === 200) {
            // vm.test_result_analysis = response.data;
            vm.$store.commit(
              "entryTestStore/setTestResultAnalysis",
              response.data
            );
          }
        }
      );
    },
    async getAllTest(page = 1) {
      let vm = this;
      await ApiService.get("prep-app/test?page=" + page).then(function (
        response
      ) {
        if (response.status === 200) {
          vm.tests = response.data.data;
          vm.data_pagination_test = response.data.meta.pagination;
        }
      });
    },
    actionSearchTest(page = 1, query = "") {
      let vm = this;
      if (this.timer_query) {
        clearTimeout(this.timer_query);
        this.timer_query = null;
      }
      this.timer_query = setTimeout(() => {
        vm.is_call_api = true;
        ApiService.get("prep-app/test?page=" + page + "&name=" + query).then(
          function (response) {
            if (response.status === 200) {
              vm.is_call_api = false;
              vm.tests = response.data.data;
              vm.test_id = vm.test_id_selected;
              vm.data_pagination_test = response.data.meta.pagination;
            }
          }
        );
      }, 1200);
    },
    btnAddOrEditEntryTest(type) {
      if (!this.passEntryTestValidate()) {
        return;
      }
      let vm = this;
      let data = {
        title: this.title_input,
        test_id: this.test_id,
        test_result_analysis_id: this.test_result_analysis_id,
        use_grading_scale: this.use_grading_scale ? 1 : 0,
        purpose: this.purpose_input,
        content: this.content_input,
      };
      if (type === 1) {
        vm.is_call_api = true;
        ApiService.post("prep-app/entry-test", data)
          .then(function (res) {
            if (res.status === 200) {
              vm.$emit("reloadEntryTestList");
              vm.swalSuccessMess("Update success");
              vm.dialogEntryTest = false;
              vm.is_call_api = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        vm.is_call_api = true;
        ApiService.put("prep-app/entry-test/" + this.entry_test_id, data)
          .then(function (res) {
            if (res.status === 202) {
              vm.$emit("reloadEntryTestList");
              vm.swalSuccessMess("Update success");
              vm.dialogEntryTest = false;
              vm.is_call_api = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    passEntryTestValidate() {
      let flat = true;

      for (let i = 0; i < this.purpose_input.length; i++) {
        if ( this.$utils.isValueInvalid(this.purpose_input[i].text)) {
          this.errorMess("Hãy nhập mục đích cho ngôn ngữ " + this.getLabelByLangCode(this.purpose_input[i].lang));
          flat = false;
        }
      }

      return flat
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.errorMess(data_error[i]);
      }
    },
    errorMess(message = 'Error') {
      this.$toasted.error(message, {
        theme: "toasted-primary",
        position: "top-right",
        duration: 4000,
      });
    },
    openDialogResultAnalysis() {
      this.dialogResultAnalysisAddFast = true;
    },
    chooseTest(index) {
      let data = this.tests[index];
      this.title_test = data.title;
    },
    openDialogTestAddFast() {
      this.dialogAddTestFast = true;
    },
    enterSearchTest() {
      let vm = this;
      vm.is_call_api = true;
      ApiService.get(
        "prep-app/test?page=" +
          1 +
          "&name=" +
          this.checkQuerySearchTest(this.search_test_input)
      ).then(function (response) {
        if (response.status === 200) {
          vm.is_call_api = false;
          vm.tests = response.data.data;
          vm.test_id = vm.test_id_selected;
          vm.data_pagination_test = response.data.meta.pagination;
        }
      });
    },
    checkQuerySearchTest(query) {
      if (query.substring(0, 1) === "#") {
        return "id-" + query.substring(1);
      }
      return query;
    },
    swalSuccessMess(mess = 'Success') {
      Swal.fire({
        title: "",
        text: mess,
        icon: "success",
        confirmButtonClass: "btn btn-secondary",
      });
    },
    getLabelByLangCode(lang = '') {
      return this.$utils.getLabelByLangCode(lang);
    },
    defaultLangContent() {
      return [
        {lang: 'vi', text: ''},
        {lang: 'en', text: ''},
        {lang: 'th', text: ''},
      ];
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.thead-ipp {
  background-color: #136495;
  color: white;
}
</style>
